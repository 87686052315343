import request from '@/auth/jwt/useJwt'

// 修改密码
export function passwordEdit(data) {
  return request.axiosIns({
    url: 'user/passwd/',
    method: 'PUT',
    data,
  })
}

// 获取个人信息
export function detail() {
  return request.axiosIns({
    url: 'user/detail',
    method: 'get',
  })
}
