<template>
  <div>
    <b-card>
      <b-tabs
        vertical
        active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
        nav-wrapper-class="nav-vertical">
        <b-tab
          active
          title="修改密码">
          <b-card-title>
            <div class="x-text-bold ml-2 mt-1">修改密码</div>
          </b-card-title>
          <b-card-body style="margin-left: -40px !important; margin-top: -25px">
            <x-form-validator ref="refFormObserver">
              <x-form-feild label="旧密码">
                <b-input-group
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model="formData.oldPassword"
                      class="form-control-merge"
                      size="lg"
                      :type="oldPasswordFieldType"
                      name="register-password"
                      placeholder="请输入旧密码"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="oldPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
                        class="cursor-pointer"
                        @click="toggle(0)"
                      />
                    </b-input-group-append>
                  </b-input-group>
              </x-form-feild>
              <x-form-feild label="新密码" require>
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    v-model="formData.newPassword"
                    class="form-control-merge"
                    size="lg"
                    :type="newPasswordFieldType"
                    name="register-password"
                    placeholder="请输入新密码"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="toggle(1)"
                    />
                  </b-input-group-append>
                </b-input-group>
              </x-form-feild>
              <x-form-feild label="确认密码" require>
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    v-model="formData.confirm"
                    class="form-control-merge"
                    size="lg"
                    :type="confirmFieldType"
                    name="register-password"
                    placeholder="请输入确认密码"
                    @keyup="check"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="confirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="toggle(2)"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger" v-if="errorFlag">与输入密码不一致</small>
              </x-form-feild>
              <b-button class="ml-1 full-width" variant="primary" v-ripple.400="$x.ripple.primary" @click="enter">修改密码</b-button>
            </x-form-validator>
          </b-card-body>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardTitle, BCardBody, BFormInput, BButton, BInputGroupAppend, BInputGroup } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import { passwordEdit } from '@/api/system/usercenter/securitysettings'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardTitle,
    BCardBody,
    XFormValidator,
    XFormFeild,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BInputGroup,
  },
  data() {
    return {
      formData: {},
      errorFlag: false,
      confirmFieldType: 'password',
      oldPasswordFieldType: 'password',
      newPasswordFieldType: 'password',
    }
  },
  created() {
  },
  methods: {
    enter() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          if (this.formData.newPassword !== this.formData.confirm) {
            this.$xtoast.warning('输入密码不一致！')
          } else {
            passwordEdit(this.formData).then(() => {
              this.$xtoast.success('修改成功！')
            })
          }
        }
      })
    },
    toggle(index) {
      if (index === 0) {
        this.oldPasswordFieldType = this.oldPasswordFieldType === 'password' ? 'text' : 'password'
      } else if (index === 1) {
        this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
      } else {
        this.confirmFieldType = this.confirmFieldType === 'password' ? 'text' : 'password'
      }
    },
    check() {
      if (this.formData.newPassword !== this.formData.confirm) {
        this.errorFlag = true
      } else {
        this.errorFlag = false
      }
    },
  },
}
</script>

<style lang="scss">
</style>
